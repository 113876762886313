import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter, ViewChildren, OnChanges } from '@angular/core';
import { AfterViewChecked, AfterViewInit } from '@angular/core';
import { HttpClient, HttpResponse, HttpEventType, HttpHeaderResponse, HttpErrorResponse } from '@angular/common/http';
import{ InteropGetURL } from '../../interop-service/interop-gettingUrl.service';
import { InteropSingleService } from '../../interop-service/interop-singleSearch.service';
import { InteropDataService } from "../../interop-service/interop-shared.service";
import {Observable} from "rxjs";
import { saveAs } from 'file-saver';
import { InteropSpinnerService } from '../../interop-service/interop-spinner.service';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { InteropNavigateService } from '../../interop-service/interop-navigate.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'interopapp-table',
  templateUrl: './interop-table.component.html',
  styleUrls: ['./interop-table.component.scss']
})


export class InteropTableComponent implements OnInit, OnChanges, AfterViewInit {
  message: string;
  @Input() tableData;
  @Input() searchInputList = [];
  @Input() flag = false;
  @Input() dropdown = [];
  private exportFile = {};
  private cableType = [];
  private formFactor = [];
  private transceiverFamily = [];
  private transceiverModel = [];
  private reach = [];
  private dataRate = [];
  private softwareType = [];
  private breakoutPatchcordRequired = [];
  private notes = [];
  fileResponse= [];
  fileCount= 0;
  fileName = null;
  selectedFiles: FileList;
  currentFile: File;
  page: number;
  searchResults: any;

  statusCreateForm: FormGroup;
  fileDescription: FormControl;
  fileToUpload: File  = null;
  uploadProgress:number = 0;
  uploadComplete:boolean = false;
  uploadingProgressing:boolean = false;
  fileUploadSub: any;
  serverResponse: any;
  productImage;
  imageError;
  @ViewChild('fileInput') myInputVariable: any;
  @Output() ResultsChanged = new EventEmitter();
  @Output() InputChanged = new EventEmitter();
  @Output() show = new EventEmitter();
  d: any;
  v: any;
  noteResults = '';
  noteResult=[];
  totalCount = 0;
  submit = [];
  urlValue: boolean;
  progress: { percentage: number } = { percentage: 0 }
  disable= false;
  tooltip="";
  uploadTooltip="";
  copiUrl;
  tmgUrl;
  config={
      dev: 'https://dpi-dev.cisco.com',
      stage:'https://dpi-stage.cisco.com',
      prod:'https://copi.cisco.com'
  }
  tmg={
    dev: 'https://tmgmatrix-dev.cisco.com/tmg',
    stage: 'https://tmgmatrix-stage.cisco.com/tmg',
    prod: 'https://tmgmatrix.cisco.com/tmg'
}



  constructor(private search: InteropSingleService, 
    private data: InteropDataService, private urlvalue: InteropGetURL, 
    private spin: InteropSpinnerService, private navigation: InteropNavigateService,
    private sanitizer: DomSanitizer
    ) {
                this.v = []
                this.totalCount = 0;
                this.urlValue= urlvalue.getUrlValue();
                // this.isScanner= this.urlvalue.getScannerValue();

                if(location.origin.includes('-dev')){
                    this.copiUrl= this.config.dev;
                    this.tmgUrl= this.tmg.dev;
                }else if(location.origin.includes('stage')){
                    this.copiUrl= this.config.stage;
                    this.tmgUrl= this.tmg.stage;

                }else{
                    this.copiUrl= this.config.prod;
                    this.tmgUrl= this.tmg.prod;

                }
            
  }



  ngOnInit() {
                if (this.flag == true) {
                    this.v = [];
                }
                for(let value of this.dropdown){
                    this.check(value); 
                } 
                this.data.FilterState.subscribe(message => {
                    this.check(message);
                });
                this.submit = [{
                    "cableType": [],
                    "dataRate": [],
                    "formFactor": [],
                    "reach": [],
                    "searchInput": this.searchInputList[0]["searchInput"],
                    "osType": [],
                    "transceiverProductFamily": [],
                    "transceiverProductID": [],
                    "networkDeviceProductFamily":[],
                    "networkDeviceProductID":[],
                    "breakoutPatchcordRequired":[]
                }];
                this.page = 1;
                this.data.currentMessage.subscribe(message => {
                    this.message = message
                });
  }

  getLink(text){
    let arr=text.split('#');
    return arr[1];
  }

  getText(text){
    let arr=text.split('#');
    return arr[0];
  }
  
  getCOPIUrl(obj){   
    // if(this.isScanner){
    //     return this.sanitizer.bypassSecurityTrustUrl(this.copiUrl+'/scanner/?tpid='+obj.productModelId);
    //   }
      if(obj.show === 'N'){
          return this.sanitizer.bypassSecurityTrustUrl(obj.transceiverModelDataSheet);
      }
      return this.sanitizer.bypassSecurityTrustUrl(this.copiUrl+'/?tpid='+obj.productModelId);
  }

  getTmgUrl(obj){
    // if(this.isScanner){
    //     return this.sanitizer.bypassSecurityTrustUrl(this.tmgUrl+'/scanner/?tpid='+obj.productModelId);
    // }
    return this.sanitizer.bypassSecurityTrustUrl(this.tmgUrl+'/?tpid='+obj.productModelId);
  }

  getProductImage(pid){
    this.imageError=null;
    this.productImage=null;
    this.spin.setdisplayValue(true);
    this.search.getIopImage(pid)
    .subscribe((res:any)=>{
        this.spin.setdisplayValue(false);
        console.log('got product image');
        this.productImage= this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,'+res.image);
    },err=>{
        this.spin.setdisplayValue(false);
        this.imageError= err.error.message;
    })
  }


  
  check(a){
            if (a != undefined) {
                var found = this.v.some(function(el) {
                    return el.id === a["id"] && el.name === a["name"];
                });
                if (!found) {
                    this.v.push(a);
                } else {
                    for (var i = 0; i < this.v.length; i++) {
                        if (this.v[i].id == a["id"] && this.v[i].name == a["name"]) {
                            this.v.splice(i, 1);
                            break;
                        }
                    }
                }
            }
    }

ngOnChanges(){
    console.log(this.tableData);
}

ngAfterViewInit(){
    var tr = document.querySelectorAll('tr');
    for(var i = 1; i < tr.length; i++) {
      tr[i].style.background = i % 2 ? '#f2f2f2' : '#fff';
    }

}


  newMessage() {
          this.data.changeMessage("Clear");
  }



  clearAll() {
            this.v = [];
            this.submit = [{
                "cableType": [],
                        "dataRate": [],
                        "formFactor": [],
                        "reach": [],
                        "searchInput": this.searchInputList[0]["searchInput"],
                        "osType": [],
                        "transceiverProductFamily": [],
                        "transceiverProductID": [],
                        "networkDeviceProductFamily":[],
                        "networkDeviceProductID":[],
                        "breakoutPatchcordRequired":[]
            }];
            this.navigation.emptyParams();
            this.navigation.navigateToURL();
  }



  saveFile(type: string) {
                this.spin.setdisplayValue(true);
                var urlResults ="";
                if(this.urlValue){
                    urlResults = "/admin/api/iop/networkdevice/search/export/excel";
                    this.search.saveFile(this.searchInputList[0], urlResults, type).subscribe(
                        (res) => {
                            if (type == 'excel') {
                                if(res.type == "application/json"){
                                    this.search.messageExport(urlResults,this.searchInputList[0]).subscribe(res=>{
                                        this.fileResponse = res.json();
                                        this.show.emit(this.fileResponse);
                                    })
                                    this.spin.setdisplayValue(false);
                                }
                                else{
                                    this.fileResponse = res;
                                this.show.emit(this.fileResponse);
                                saveAs(res, "IOPADM.xlsx"); //if you want to save it - you need file-saver for this : https://www.npmjs.com/package/file-saver
                                this.spin.setdisplayValue(false);
                            }
                            } 
                        }
                    );
                }
                else{
                        urlResults = "/public/api/iop/networkdevice/search/export/" + type;
                        this.search.saveFile(this.searchInputList[0], urlResults, type).subscribe(
                            (res) => {
                             if (type == 'excel') {
                                this.fileResponse = res;
                                this.show.emit(this.fileResponse);
                                    saveAs(res, "IOPMXL.xlsx"); //if you want to save it - you need file-saver for this : https://www.npmjs.com/package/file-saver
                                    this.spin.setdisplayValue(false);
                                
                                } else if (type == 'pdf') {
                                    this.fileResponse = res;
                                    this.show.emit(this.fileResponse);
                                    saveAs(res, "IOPMPDF.pdf"); //if you want to save it - you need file-saver for this : https://www.npmjs.com/package/file-saver
                                    this.spin.setdisplayValue(false);
                                
                                } else if (type == 'csv') {
                                   
                                    this.fileResponse = res;
                                    this.show.emit(this.fileResponse);
                                    saveAs(res, "IOPMCSV.csv"); //if you want to save it - you need file-saver for this : https://www.npmjs.com/package/file-saver
                                    this.spin.setdisplayValue(false);
                                
                                }
                            }
                        );
                    }
  }



  showMessage(){
            this.show.emit(this.fileResponse);
            this.search.getResults(this.searchInputList[0]).subscribe(res=>{
                this.tableData=res;
                this.ResultsChanged.emit(this.tableData);
            })
            sessionStorage.clear();
  }
   


  selectFile(event){
            this.selectedFiles = event.target.files;
            if(this.selectedFiles[0].name.includes('.xlsx')){
                this.fileName = this.selectedFiles[0].name
              
              }
  }

  closeModal(){
      this.fileName=null;
  }

  

  showMiniNotes(transceiverFamilyNotes) {
      this.noteResult = [];
      console.log(transceiverFamilyNotes)
      if(transceiverFamilyNotes === null){
          this.noteResults = 'Notes not available for this Transciever Compatible'
      }
      else{
        this.noteResults = transceiverFamilyNotes;
      }
              

              console.log(this.noteResults)
  }

  showNotes(networkModelName, transceiverModelName,productModelId,networkModelId, versionId, releaseId,noteCount) {
    this.noteResult = [];
    this.noteResults = null;
    this.notes = [networkModelName, transceiverModelName]
    this.search.getNotes({
        "networkModelId": networkModelId,
        "softwareReleaseId": releaseId,
        "transceiverModelId": productModelId,
        "transceiverVersionId": versionId
    }).subscribe(res => {
        // this.noteResult = Object.values(res);
        this.noteResult = Object.keys(res).map(itm => res[itm]);
    });
}

newTab(){
    let URL = window.location.href;
    if(URL.includes('dev')){
     window.open('https://tmgmatrix-dev.cisco.com/tmg');
    }
    else if (URL.includes('stage')){
     window.open('https://tmgmatrix-stage.cisco.com/tmg')
    }
    else if(URL.includes('https://tmgmatrix.cisco.com/iop')){
     window.open('https://tmgmatrix.cisco.com/tmg');
    }
}
  
  close(obj) {
            this.navigation.editFilters(obj,obj.filtername,false);
            this.navigation.navigateToURL();
  }


  
}
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { HomeComponent} from '../component/home/home.component';
import{ AppComponent } from '../component/app/app.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import {Component} from '@angular/core';
// import { CiscoLiveHomeComponent } from "../cisco-live/cisco-live-component/cisco-live-home/cisco-live-home.component";

const appRoutes: Routes = [
  { path: 'home', component: HomeComponent},
  //{ path: 'public', component: AppComponent}
  
];

export const appRoutingProviders: any[] = [
  {provide: LocationStrategy, useClass: PathLocationStrategy}
];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);

import { Component, HostListener, OnInit, Output, ViewChild, EventEmitter, ElementRef} from '@angular/core';
import{ InteropSuggesionService } from "../../interop-service/interop-autoSuggestion.service";
import { InteropSingleService } from '../../interop-service/interop-singleSearch.service';
import { Observable ,  Subscription } from 'rxjs';
import{ InteropSuggestPipe } from './interop-suggestion-pipe.pipe';

import { InteropFiltersComponent } from '../interop-filters/interop-filters.component';

import { InteropCapitalizePipe } from './interop-capitalize.filter';
import { InteropTableComponent } from '../interop-table/interop-table.component';
import { InteropDataService } from "../../interop-service/interop-shared.service";
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { InteropRouteInformationService } from '../../interop-service/interop-routeInformation.service';
import { InteropNavigateService } from '../../interop-service/interop-navigate.service';
import { ScannerService } from '../../../service/scanner.service';
import { InteropGetURL } from '../../interop-service/interop-gettingUrl.service';

@Component({
  selector: 'interopapp-home',
  templateUrl: './interop-home.component.html',
  styleUrls: ['./interop-home.component.scss']
})
export class InteropHomeComponent implements OnInit {

    @ViewChild('myElement') myInputVariable: any;
  @Output() uploadResponse = new EventEmitter();
  private busy: Subscription;
  public show: boolean;
  public showSuggession: boolean;
  private suggestionList: any;
  private inputId = "singleSearch";
  private input;
  private searchValue;
  private search$: any;
  public selectedOptions: any;
  private searchList = [];
  private submit: any;
  private searchResults: any;
  private filterResults: any;
  private clearFlag = false;
  private messageDetails;
  private currentValue:any[];
  private uploadFile: any;
 private selectedItem: any;
 private arrowkeyLocation= -1;
 private suggestionid: any;
 private inputField: any;
 private length: any;
 private suggestionarray= [];
 private reach: any;
 private routeFilters: any;
 private routeResponse: any;

 

 help:boolean=false;
 helpDetails;
 scannerOnline:boolean=false;
 showTimeout:boolean=false;
 showScanError:boolean=false;
 errMsg;

 copiUrl

  config={
      dev: 'https://dpi-dev.cisco.com',
      stage:'https://dpi-stage.cisco.com',
      prod:'https://copi.cisco.com'
  }


 @ViewChild('closeModal') closeModal: ElementRef;




  constructor(private suggestion: InteropSuggesionService, 
    public search: InteropSingleService, 
    private data: InteropDataService, 
    private route: ActivatedRoute, 
    private routeService: InteropRouteInformationService, 
    private router: Router, private navigation: InteropNavigateService,
    private sanitizer:DomSanitizer, private scannerService: ScannerService,
      private getUrl: InteropGetURL
    ) {
                    this.selectedOptions = null;
                    this.searchList = [];
                    this.currentValue = [];
                    this.input=null;
                    
                    if(location.origin.includes('-dev') || location.origin.includes('localhost')){
                        this.copiUrl= this.config.dev;
                       
                    }else if(location.origin.includes('stage')){
                        this.copiUrl= this.config.stage;
                       
                    }else{
                        this.copiUrl= this.config.prod;
                       
                    }

                    this.submit = [{
                        "cableType": [],
                        "dataRate": [],
                        "formFactor": [],
                        "reach": [],
                        "searchInput": [],
                        "osType": [],
                        "transceiverProductFamily": [],
                        "transceiverProductID": [],
                        "networkDeviceProductFamily":[],
                        "networkDeviceProductID":[],
                        "breakoutPatchcordRequired": []
                    }];

                    this.routeFilters = {
                        "cableType": [],
                        "dataRate": [],
                        "formFactor": [],
                        "reach": [],
                        "searchInput": [],
                        "osType": [],
                        "transceiverProductFamily": [],
                        "transceiverProductID": [],
                        "networkDeviceProductFamily":[],
                        "networkDeviceProductID":[],
                        "breakoutPatchcordRequired": []
                    };
  }



  ngOnInit() {

    this.router.events.subscribe(
        (event) => {
            // console.log('event',event);
            if (event instanceof NavigationEnd) {
                console.log('navigation end');
                // this.scanner=this.getUrl.getScannerValue();
                // if(this.scanner){
                    this.scannerService.getHelpTips(this.copiUrl)
                    .subscribe(res=>{
                        this.helpDetails= res;
                        console.log('help details: ',this.helpDetails);
                    })
                // }
          }
        }
      );

   
                this.showSuggession = false;
                this.show = false;    

                
                this.route.queryParamMap.subscribe(params=>{
                    this.routeFilters["cableType"] = params.getAll("ct");
                    this.routeFilters["dataRate"] = params.getAll("dr");
                    this.routeFilters["formFactor"] = params.getAll("ff");
                    this.routeFilters["reach"] = params.getAll("rh");
                    this.routeFilters["searchInput"] = params.getAll("si");
                    this.routeFilters["osType"] = params.getAll("os");
                    this.routeFilters["transceiverProductFamily"] = params.getAll("tpf");
                    this.routeFilters["transceiverProductID"] = params.getAll("tpid");
                    this.routeFilters["networkDeviceProductFamily"] = params.getAll("npf");
                    this.routeFilters["networkDeviceProductID"] = params.getAll("npid");
                    this.routeFilters["breakoutPatchcordRequired"] = params.getAll("brk");

                    var isRoute = false;
                    for(let route in this.routeFilters){
                        if(route=="searchInput")
                        continue;

                        if(this.routeFilters[route].length>0)
                        {isRoute=true;}
                    }

                    if(isRoute){
                        this.routeService.getRouteInfo(this.routeFilters).subscribe(res => {
                            this.submit = [{
                                "cableType": [],
                                "dataRate": [],
                                "formFactor": [],
                                "reach": [],
                                "searchInput": [],
                                "osType": [],
                                "transceiverProductFamily": [],
                                "transceiverProductID": [],
                                "networkDeviceProductFamily":[],
                                "networkDeviceProductID":[],
                                "breakoutPatchcordRequired":[]
                            }];
                                this.navigation.autoFill(res,this.routeFilters["searchInput"]);
                                this.routeSearch(res,this.routeFilters["searchInput"]);
                        })
                    }
                    else if(this.navigation.fullSearch||this.routeFilters["searchInput"].length>0){
                        this.submit = [{
                            "cableType": [],
                            "dataRate": [],
                            "formFactor": [],
                            "reach": [],
                            "searchInput": [],
                            "osType": [],
                            "transceiverProductFamily": [],
                            "transceiverProductID": [],
                            "networkDeviceProductFamily":[],
                            "networkDeviceProductID":[],
                            "breakoutPatchcordRequired":[]
                        }];
                        this.fullSearch(this.routeFilters["searchInput"]);
                    }
                })    

  }



  ngAfterViewInit() {
               this.input = document.getElementById(this.inputId);
  }

  closeScannerApp(result){
    console.log('close scanner? ',result);
    this.closeScanner();
    this.closeModal.nativeElement.click();
    if(result){
            if(result.timeout){
                this.showTimeout=true;
            }else if(result.error){
                this.showScanError=true;
                this.errMsg= result.error.message;

            }
            else{
                this.navigate('transceiverProductID',result);
            }
        }
    }

    closeRes(event){
        if(event== 'timeout'){
            this.showTimeout=false;
        }
        if(event== 'error'){
            this.showScanError =false;
            this.errMsg=null;
        }
    }

  enableScanner(){
    this.scannerOnline=true;
    }

  closeScanner(){
        this.help=false;

        this.scannerOnline=false;
    }

    Help(){
        this.help=!this.help;
        if(this.help){
            this.scannerOnline=false;
        }else{
            this.scannerOnline=true;
        }

    }

  getHelpImage(image_blob){

    let objUrl='data:image/jpeg;base64,'+image_blob;
    let thumbnail=this.sanitizer.bypassSecurityTrustUrl(objUrl);
    return thumbnail;
    }
 


  keyDown(e){
     this.arrowkeyLocation= ++this.arrowkeyLocation;
            var suggestionarray=this.suggestionarray;
            var array = new Array();
            let myArray: any;
                    for(let i in this.suggestionList){
                        for(let j in this.suggestionList[i]){
                            myArray = document.getElementById(this.suggestionList[i][j].name+i)
                            array.push(myArray.id);
                        }
                    }
                    this.suggestionarray=array;
                    if(suggestionarray.length != this.suggestionarray.length){
                    this.arrowkeyLocation =0;
                    }
                    else if(this.arrowkeyLocation >array.length-1){
                        this.arrowkeyLocation=0;
                    }
                    for(let i=0; i<array.length; i++){
                        if(i === this.arrowkeyLocation){
                            this.suggestionid =array[i];
                        }
                    }
  }



  keyUp(e){
    var suggestionarray=this.suggestionarray;
            var array = new Array();
            let myArray: any;
            this.arrowkeyLocation--;
                for(let i in this.suggestionList){
                    for(let j in this.suggestionList[i]){
                        myArray = document.getElementById(this.suggestionList[i][j].name+i)
                        array.push(myArray.id);
                    }
                }
                this.suggestionarray=array;
                if(suggestionarray.length != this.suggestionarray.length){
                this.arrowkeyLocation =0;
                }
                else if(this.arrowkeyLocation <0){
                    this.arrowkeyLocation= array.length-1;
                }
                for(let i=0; i<array.length; i++){
                if(i === this.arrowkeyLocation){
                    this.suggestionid =array[i];
                }
            }
  }



  autoSuggest(e) {

                 if(this.showSuggession){
                            var value= this.input.value;
                            if(this.input.value == ""){
                                this.showSuggession = false;
                            }
                            else if(e.keyCode == 38){
                                this.keyUp(e);
                            }
                            else if(e.keyCode == 40){
                                this.keyDown(e);
                            }
                            else if(e.keyCode === 13){
                                if(this.suggestionid == undefined){
                                    this.searchValue = this.input.value;
                                    this.fetchResults(this.input.value)
                                    this.showSuggession = false;
                                    this.input.value=value;
                                    this.suggestionList =[];
                                this.showSuggession=false;
                                this.suggestionid=null;
                                this.inputField=value;
                                }
                                else{
                                for(let i in this.suggestionList){
                                    for(let j in this.suggestionList[i]){
                                    let myArray = document.getElementById(this.suggestionList[i][j].name+i)
                                    if(this.suggestionid == myArray.id){
                                        myArray.click();
                                        this.input.value=null;
                                    }
                                    }
                                }
                            }
                                this.suggestionList =[];
                                this.showSuggession=false;
                                this.suggestionid=null;
                            }
                            else{

                                if (this.input.value.trim().length < 2) {
                                    this.suggestionList = [];
                                } else {
                                    var pieces = this.input.value.split(/[,]+/);
                                    var val = pieces[pieces.length - 1];
                                    if (val.length >= 2) {
                                        this.suggestion.getResult(val).subscribe(res => {
                                            this.suggestionList = res
                                            this.showSuggession = true;
                                            this.arrowkeyLocation= -1;
                                            this.suggestionid=undefined;
                                        });
                                    }
                                }
                            }
                 }
                 else{
                            if (e.keyCode === 188) {
                                this.showSuggession = false;
                            }

                            if (e.keyCode === 13 || e.type === 'typeaheadClick') {

                                this.searchValue = this.input.value;
                                this.fetchResults(this.input.value)
                                this.showSuggession = false;
                                this.input.value = this.searchValue;
                                this.inputField=this.searchValue;

                            } else {

                                this.showSuggession = false;
                            }
                             if(this.inputField != this.input.value){
                                 this.suggestionid=undefined;
                            if (this.input.value.trim().length < 2) {
                                this.suggestionList = [];
                            } else {
                                    
                                var pieces = this.input.value.split(/[,]+/);
                                var val = pieces[pieces.length - 1];
                                if (val.length >= 2) {
                                    this.suggestion.getResult(val).subscribe(res => {
                                        this.suggestionList = res
                                        this.showSuggession = true;
                                        this.arrowkeyLocation= -1;
                                        this.suggestionid=undefined;
                                    });
                                }
                            }
                        }
                    }
     }



  fullSearch(searchInput) {
    if(searchInput&&searchInput[0]!=""){
        var input = "";
        for(var i=0;i<searchInput.length;i++){
            if(i==searchInput.length-1)
            input = input.concat(searchInput[i]);
            else
            input= input.concat(searchInput[i]+",");
        }
        this.selectedOptions = input;

        this.submit[0]["searchInput"] = searchInput;
    }

    this.input.focus();
  
    this.search.getResults(this.submit[0]).subscribe(res => {

        this.searchResults = res
        this.uploadFile=res;
        this.show = true;
        this.showSuggession = false;
        this.suggestionList= [];
    });
    this.showSuggession=false;
    this.navigation.fullSearch = false;
  }



  routeSearch(res,searchInput) {
    this.currentValue = [];
    if(searchInput&&searchInput[0]!=""){
        var input = "";
        for(var i=0;i<searchInput.length;i++){
            if(i==searchInput.length-1)
            input = input.concat(searchInput[i]);
            else
            input= input.concat(searchInput[i]+",");
        }
        this.selectedOptions = input;

        this.submit[0]["searchInput"] = searchInput;
    }
        
    for(let filtername in res){
        for(let value of res[filtername]){
            if(filtername == "ostype"){
                filtername= "osType";
            }
            value.count = 0;
            value.filterChecked = true;
            value["filtername"] = filtername;
            this.submit[0][filtername].push(value);
            this.currentValue.push(value);

            if(this.data.data.length>0){
            var change = true;
            for(let filterState of this.data.data){
                if(filterState["id"]==value.id&&filterState["name"]==value.name)
                change = false;
            }
           
            if(change){
                this.data.addFilter(value,filtername);
                this.data.addToData(value,filtername);
            }
            }
            else{
                this.data.addFilter(value,filtername);  
                this.data.addToData(value,filtername);
            }
        }
    }
      this.showSuggession = false;
      this.search.getResults(this.submit[0]).subscribe(res => {

          this.searchResults = res
          this.uploadFile=res;
          this.show = true;
          this.showSuggession = false;
          this.suggestionList= [];
      });
      this.showSuggession=false;

}



  fetchResults(searchString: string) {
                this.showSuggession = false;
                let isEmpty = true;
                for(let filtername in this.submit[0]){
                    if(this.submit[0][filtername].length>0)
                        isEmpty = false;
                }
                if(searchString==""&&isEmpty){
                    this.search.getResults(this.submit[0]).subscribe(res=>{
                        this.searchResults = res
                        this.uploadFile=res;
                        this.show = true;
                        this.showSuggession = false;
                        this.suggestionList= [];
                    })
                }
                else{
                var input = searchString;
                if (input.substring(input.length - 1) == ",") {
                    input = input.substring(0, input.length - 1);
                }
                this.searchList = input.split(',');
                this.navigation.editFilters(this.searchList,"searchInput",false);
                this.navigation.navigateToURL();
                this.showSuggession= false;
                this.suggestionList = [];
            }
  }



  SearchResultsHandler(results: any) {
      this.searchResults = results;

  }



  InputHandler(input: any) {
      this.submit = input;
  }



  sendResponse(res: any){
      this.uploadFile=res;
  this.uploadResponse.emit(this.uploadFile);
  }


  
  newSearchHandler(flag) {
      this.clearFlag = flag;
  }



  navigate(filtername, value){
    if(this.selectedOptions){
        var input = this.selectedOptions;
        this.selectedOptions = this.selectedOptions.substring(0, this.selectedOptions.lastIndexOf(",") + 1);
                    if (this.selectedOptions.substring(this.selectedOptions.length - 1) == ",") {
                        this.selectedOptions = this.selectedOptions.substring(0, input.length - 1);
                    }
        this.searchList = this.selectedOptions.split(',').filter(Boolean);
        this.navigation.editFilters(this.searchList,"searchInput",false);
    }

      this.navigation.editFilters(value, filtername,true);
      this.navigation.navigateToURL();
      this.showSuggession = false;
      this.suggestionList = [];
  }



  clickedInside($event: Event){
    $event.preventDefault();
    $event.stopPropagation();  // <- that will stop propagation on lower layers
  }


  
  @HostListener('document:click', ['$event']) clickedOutside($event){
    this.showSuggession = false;
  }



}
import { Component, OnInit, Input, Output, EventEmitter, DoCheck, IterableDiffers, OnChanges } from '@angular/core';
import { FilterpipePipe } from './filterpipe.pipe';
import { NospacePipe } from './nospace.pipe';
import { SingleService } from '../../service/singleSearch.service';
import { DataService } from "../../service/shared.service";
// import { CookieService } from 'angular2-cookie/services/cookies.service';
import { NavigateService } from '../../service/navigate.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit, OnChanges {

    @Input() searchInputList = [];
    @Input() jData;
    @Input() flag;
    @Input() filterResults = [];

    index = 0;
    alphabets=['A','B','C','D','E','F','G','H','I','J','K','L','M','N',
    'O','P','Q','R','S','T','U','V','W','X','Y','Z','0',
    '1','2','3','4','5','6','7','8','9'];
    currentName: any;
    flag1 = false;
    cabletype: any;
    formfactor: any;
    ostype: any;
    transceiverproductfamily: any;
    transceiverproductid: any;
    networkdeviceproductfamily: any;
    networkdeviceproductid: any;
    filterName = [];
    count = 0;
    fNames: any;
    searchResults: any;
    currentFilter: any;
    contents: any;
    showmore: any;
    length = null;
    filtername: any;
    message: string;
    selectedFilters: Array<{}>;
    modelName: any;
    counter: any;
    content: any;
    open: any;
    num= 0;
    submit = [];
    isActive = "ALL";
    @Output() ResultsChanged = new EventEmitter();
    @Output() Changed = new EventEmitter();
    @Output() InputChanged = new EventEmitter();


    constructor(private search: SingleService, private data: DataService, 
        private navigation: NavigateService, private cookies: CookieService) {

    }



    refine(alpha){
          document.getElementById(this.isActive).classList.remove("active");
          this.isActive = alpha;

                if(alpha=="ALL"){
                    this.contents=this.showmore;
                    document.getElementById("ALL").classList.add("active"); 
                }
                else{
                    let alphabet = alpha.toLowerCase();
                    this.contents=[];
                    for(let i in this.showmore){
                        if(this.showmore[i]["name"].charAt(0).toLowerCase() == alphabet){
                            this.contents.push(this.showmore[i]);
                        }
                    }
                    document.getElementById(alpha).classList.add("active");
                }
    }



    ngOnChanges(searchInputList) {
                var name: any;
                this.cookies.deleteAll();
                this.cookies.set('dataRate','datarate');
                this.cookies.set('reach','reach');
                for(let i in this.searchInputList){
                    for(let j in this.searchInputList[i]){
                        if(j != "dataRate" && j != "reach" && j != "searchInput"){
                            if(this.searchInputList[i][j].length>0){
                                this.cookies.set(j,j.toLowerCase());     
                            }
                        }
                    }
                }

                for(let i in this.jData){
                    if(i == "filters"){
                        for(let j in this.jData["filters"]){
                            this.jData["filters"][j].values.sort(function(a,b){
                                if (a.name < b.name){
                                    return -1;
                                }else if(a.name > b.name){
                                    return 1;
                                }else{
                                    return 0;
                                }
                            }); 
                        }
                    }
                }
                
                
    }


    
  getCookies(key){
                if(key == "OS Type"){
                    key = key.replace(/\s/g, '').charAt(0).toLowerCase()+key.replace(/\s/g, '').charAt(1).toLowerCase()+key.replace(/\s/g, '').slice(2);
                    key
                }
                else{
                key = key.replace(/\s/g, '').charAt(0).toLowerCase()+key.replace(/\s/g, '').slice(1)
                }
                if(this.cookies.get(key)){
                    return true;
                }
                else{
                    return true;
                }
  }



    ngOnInit() {
                this.cookies.deleteAll();
                this.cookies.set('dataRate','datarate');
                this.cookies.set('reach','reach');
                for(let i in this.searchInputList){
                    for(let j in this.searchInputList[i]){
                        if(j != "dataRate" && j != "reach" && j != "searchInput"){
                            if(this.searchInputList[i][j].length>0){
                                this.cookies.set(j,j.toLowerCase());     
                            }
                        }
                    }
                }    
                this.flag1 = true;
                this.selectedFilters = [];
                this.submit = this.searchInputList;
                  this.data.currentMessage.subscribe(message => {
                    this.message = message;
                     if (this.message == "Clear") {
                        this.submit = [{
                            "cableType": [],
                            "dataRate": [],
                            "formFactor": [],
                            "reach": [],
                            "searchInput": this.searchInputList[0]["searchInput"],
                            "osType": [],
                            "transceiverProductFamily": [],
                            "transceiverProductID": [],
                            "networkDeviceProductFamily": [],
                            "networkDeviceProductID": []
                        }];
                    }
                }); 

                for(let i in this.jData){
                    if(i == "filters"){
                        for(let j in this.jData["filters"]){
                            this.jData["filters"][j].values.sort(function(a,b){
                                if (a.name < b.name){
                                    return -1;
                                }else if(a.name > b.name){
                                    return 1;
                                }else{
                                    return 0;
                                }
                            }); 
                        }
                    }
                }

                console.log('jData ',this.jData);

    }



    getData(filterval: any[], modelName) {
                    //document.getElementById("ALL").classList.add('active');
                    this.modelName = modelName;
                    this.counter = 4;
                    this.contents = new Array();
                    for (let j = this.counter; j < filterval.length; j++) {
                        this.contents.push(filterval[j]);
                        this.counter = filterval.length;
                    }
                    this.contents.sort(function(a,b){
                        if (a.name < b.name){
                            return -1;
                        }else if(a.name > b.name){
                            return 1;
                        }else{
                            return 0;
                        }
                    });
                    this.showmore=this.contents;
                    //this.refine("ALL");
                }


                
    checkedPopup(value, e) {
                // if (this.flag == true) {
                //     this.flag = false;
                //     this.Changed.emit(this.flag);
                // }
                this.common(value, e);
    }



    common(value, e) {
                    this.num = 0;
                    this.submit=this.searchInputList;
                    if(this.modelName == "OS Type"){
                        this.filtername = this.modelName.replace(/\s/g, '').charAt(0).toLowerCase()+ this.modelName.replace(/\s/g, '').charAt(1).toLowerCase()+ this.modelName.replace(/\s/g, '').slice(2);
                    }
                    else{
                    this.filtername = this.modelName.replace(/\s/g, '').charAt(0).toLowerCase() + this.modelName.replace(/\s/g, '').slice(1);
                    }
                    this.navigation.editFilters(value,this.filtername,false);
                    if (e.target.checked == true) {
                        this.num = this.num + 1;
                        this.count = this.count + 1;
                    } else {
                                this.count = this.count - 1;
                                this.num = this.num + 1;    
                    }
    }



    callSearch() {
                document.getElementById(this.isActive).classList.remove("active");
                if (this.count > 0 && this.num != 0) {
                    this.navigation.navigateToURL();
                }
                else if(this.num != 0){
                    this.navigation.navigateToURL();
                }
                
                this.num=0;
    }




    checked(filtername, value, e) {

                        this.currentFilter = null;
                        
                        if (this.message == "Clear") {
                            this.submit = [{
                                "cableType": [],
                                "dataRate": [],
                                "formFactor": [],
                                "reach": [],
                                "searchInput": this.searchInputList[0]["searchInput"],
                                "osType": [],
                                "transceiverProductFamily": [],
                                "transceiverProductID": [],
                                "networkDeviceProductFamily": [],
                                "networkDeviceProductID": []
                            }];
                            this.message = "on";
                            this.data.changeMessage(this.message);
                        }
                        this.submit=this.searchInputList;
                        value.filterChecked = true;
                        this.selectedFilters.push(value);
                        if (filtername != "OS Type") {
                            this.filtername = filtername.replace(/\s/g, '').charAt(0).toLowerCase() + filtername.replace(/\s/g, '').slice(1);
                        }
                        else { this.filtername = filtername.replace(/\s/g, '').charAt(0).toLowerCase() + filtername.replace(/\s/g, '').charAt(1).toLowerCase() + filtername.replace(/\s/g, '').slice(2); }
                        if (e.target.checked == true) {
                            this.currentName = filtername;
                        } 
                        
                        this.navigation.editFilters(value,this.filtername,false);
                        this.navigation.navigateToURL();
                        
    }




    toggle(event, name: string, index: any) {
        console.log('event ',event);

                        var fname="";
                        if(name == "OS Type"){
                        fname= name.replace(/\s/g, '').charAt(0).toLowerCase()+name.replace(/\s/g, '').charAt(1).toLowerCase()+name.replace(/\s/g, '').slice(2);
                    } 
                    else {
                        fname= name.replace(/\s/g, '').charAt(0).toLowerCase()+name.replace(/\s/g, '').slice(1);
                    }
                        this.cookies.set(fname,name.replace(/\s/g, '').toLowerCase());
                        this.index = index;
                        this.flag = true;
                        this.currentName = name;
                        name = name.replace(/\s/g, '').toLowerCase();
                        console.log('fname: ',fname);
                        let style = document.getElementById(fname);
                        console.log('style element',style);
                    if (style.style.display == "block" ) {
                            style.style.display= "none";
                            event.target.classList.remove('icon-remove-outline');
                            event.target.classList.add('icon-add-outline');
                            this.cookies.delete(fname);
                            this.currentFilter = null;

                        } 
                        else {
                            style.style.display="block";
                            event.target.classList.remove('icon-add-outline');
                            event.target.classList.add('icon-remove-outline');
                        }

    }




    check(e, filval: any, id) {
                    const val = e.target.value;
                    if (val == null || val == "") {
                        this.currentFilter = null;
                        this.length = null;
                        this.content = null;
                    } else {
                        this.length = null;
                        this.length = filval.length;
                        this.currentFilter = id;
                        this.content = new Array();
                        this.content = filval.filter(filvals => filvals.name.toLowerCase().indexOf(val.toLowerCase()) !== -1)
                    }
    }

}